// PAGE TITLE
export const TITLE_PREFIX = 'Cycle Count';

// ROUTES
export const CYCLE_ACTIVE_ROUTE = '/cycles';
export const CYCLE_HISTORICAL_ROUTE = '/historical-cycles';
export const CYCLE_DETAILS_ROUTE = '/cycles/:id';
export const CYCLE_WAVE_DETAILS_ROUTE = '/cycles/wave-details/:id';
export const CYCLE_MANAGER_ROUTE = '/cycle-count/manager-review';
export const CYCLE_GROUP_ASSIGNMENT_ROUTE = '/cycle-count/group-assignments';
export const CYCLE_GROUP_MISSING_ASSIGNMENT_ROUTE = '/cycle-count/group-missing-assignments';
