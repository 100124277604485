export const PREFFERED_REPORTS_ROUTE = '/reports';

export default {
    name: 'reports',
    before: [],
    after: [],
    setup: (app) => {
        const route = PREFFERED_REPORTS_ROUTE;
        const label = 'Reports';
        const permission = [];

        app.routes.addItem('graetersReports', {
            path: route,
            title: label,
            permission,
            props: () => ({}),
            loader: () => import('./packages/Reports/core'),
        });

        app.sideMenuItems.addItem('reports', {
            label,
            link: PREFFERED_REPORTS_ROUTE,
            icon: 'resInventory',
            permission,
            order: 70,
        });
    },
};
