import {
    WAREHOUSE_TITLE_PREFIX,
    WAREHOUSES_GRID_ROUTE,
    WAREHOUSES_DETAILS_PATH,
    WAREHOUSES_DETAILS_ROUTE,
    WAREHOUSES_FORM_PATH,
    WAREHOUSES_FORM_ROUTE,
    WAREHOUSES_DT_FORM_PATH,
    WAREHOUSES_DT_FORM_ROUTE,
    WAREHOUSES_LOCATION_GRID_PATH,
    WAREHOUSES_LOCATION_GRID_ROUTE,
    WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH,
    WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_ROUTE,
    DESCRIPTORS_TITLE_PREFIX,
    DESCRIPTORS_GRID_ROUTE,
    DESCRIPTORS_FORM_PATH,
    DESCRIPTORS_FORM_ROUTE,
    DESCRIPTION_TEMPLATES_TITLE_PREFIX,
    DESCRIPTION_TEMPLATES_GRID_ROUTE,
    DESCRIPTION_TEMPLATES_FORM_PATH,
    DESCRIPTION_TEMPLATES_FORM_ROUTE,
    DESCRIPTION_TEMPLATES_D_FORM_PATH,
    DESCRIPTION_TEMPLATES_D_FORM_ROUTE,
    DESCRIPTION_TEMPLATES_WAREHOUSES_FORM_PATH,
    DESCRIPTION_TEMPLATES_WAREHOUSES_FORM_ROUTE,
    WAREHOUSE_CONFIGURATION_TITLE_PREFIX,
    WAREHOUSE_CONFIGURATION_GRID_ROUTE,
    WAREHOUSE_CONFIGURATION_FORM_PATH,
    WAREHOUSE_CONFIGURATION_FORM_ROUTE,
    CARTS_GRID_ROUTE,
    CARTS_TOTES_GRID_PATH,
    CARTS_TOTES_GRID_ROUTE,
    CARTS_FORM_PATH,
    CARTS_FORM_ROUTE,
    TOTES_GRID_ROUTE,
    TOTES_FORM_PATH,
    TOTES_FORM_ROUTE,
} from './consts';

import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

export default (app) => ({
    warehouses: {
        path: app.settingsWarehousesTabs.getItem('warehouses').link,
        title: app.settingsWarehousesTabs.getItem('warehouses').title,
        permission: app.settingsWarehousesTabs.getItem('warehouses').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('warehouses').label}`,
            clearPath: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSES_FORM_ROUTE,
            detailsPageRoute: WAREHOUSES_DETAILS_ROUTE,
            locationsPageRoute: WAREHOUSES_LOCATION_GRID_ROUTE,
            locationsProductsPageRoute: WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/warehouses-grid'),
        render: withSettingsTabsWrapper(app),
    },
    warehouseForm: {
        path: WAREHOUSES_FORM_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Warehouse', isReplaceble: true },
        props: () => ({
            indexPageRoute: WAREHOUSES_GRID_ROUTE,
            detailsPageRoute: WAREHOUSES_DT_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/warehouse-form'),
    },
    warehouseDetails: {
        path: WAREHOUSES_DETAILS_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Details`,
        breadcrumbConfig: { title: `Details`, isReplaceble: true },
        props: () => ({
            gridPageRoute: WAREHOUSES_GRID_ROUTE,
            updateFormPageRoute: WAREHOUSES_FORM_ROUTE,
            locationsPageRoute: WAREHOUSES_DETAILS_ROUTE,
        }),
        loader: () => import('@skunexus/warehouse-details'),
    },
    warehouseLocations: {
        path: WAREHOUSES_LOCATION_GRID_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} > Locations`,
        permission: ['warehouses.index'],
        breadcrumbConfig: { title: `Locations`, isReplaceble: true },
        props: () => ({}),
        loader: () => import('@skunexus/warehouse-locations'),
    },
    warehouseLocationsProducts: {
        path: WAREHOUSES_LOCATION_WITH_PRODUCTS_GRID_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} > Products by Location`,
        permission: ['warehouses.index'],
        breadcrumbConfig: { title: `Products by Location`, isReplaceble: true },
        props: () => ({
            productDetailsStockPageRoute: '/products/stock',
        }),
        loader: () => import('@skunexus/warehouse-locations-products'),
    },
    warehouseDtForm: {
        path: WAREHOUSES_DT_FORM_PATH,
        title: `${WAREHOUSE_TITLE_PREFIX} Description Templates`,
        breadcrumbConfig: { title: 'Add Description Template', isReplaceble: true },
        props: () => ({
            indexPageRoute: WAREHOUSES_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/warehouse-dt-form'),
    },
    descriptors: {
        path: app.settingsWarehousesTabs.getItem('descriptors').link,
        title: app.settingsWarehousesTabs.getItem('descriptors').title,
        permission: app.settingsWarehousesTabs.getItem('descriptors').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('descriptors').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: DESCRIPTORS_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/descriptors-grid'),
        render: withSettingsTabsWrapper(app),
    },
    descriptorsForm: {
        path: DESCRIPTORS_FORM_PATH,
        title: `${DESCRIPTORS_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Descriptor', isReplaceble: true },
        props: () => ({
            indexPageRoute: DESCRIPTORS_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/descriptors-form'),
    },
    descriptorTemplates: {
        path: app.settingsWarehousesTabs.getItem('descriptorTemplates').link,
        title: app.settingsWarehousesTabs.getItem('descriptorTemplates').title,
        permission: app.settingsWarehousesTabs.getItem('descriptorTemplates').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('descriptorTemplates').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: DESCRIPTION_TEMPLATES_FORM_ROUTE,
            warehousesFormPageRoute: DESCRIPTION_TEMPLATES_WAREHOUSES_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/description-templates-grid'),
        render: withSettingsTabsWrapper(app),
    },
    descriptorTemplatesForm: {
        path: DESCRIPTION_TEMPLATES_FORM_PATH,
        title: `${DESCRIPTION_TEMPLATES_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Description Template', isReplaceble: true },
        props: () => ({
            indexPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE,
            detailsPageRoute: DESCRIPTION_TEMPLATES_D_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/description-templates-form'),
    },
    descriptorTemplatesDForm: {
        path: DESCRIPTION_TEMPLATES_D_FORM_PATH,
        title: `${DESCRIPTION_TEMPLATES_TITLE_PREFIX} Form - Descriptors`,
        breadcrumbConfig: { title: 'Add Descriptor', isReplaceble: true },
        props: () => ({
            indexPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE,
            detailsPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE, // there is none so we redirect to index page
        }),
        loader: () => import('@skunexus/description-templates-d-form'),
    },
    descriptorTemplatesWarehousesForm: {
        path: DESCRIPTION_TEMPLATES_WAREHOUSES_FORM_PATH,
        title: `${DESCRIPTION_TEMPLATES_TITLE_PREFIX} Form - Warehouses`,
        breadcrumbConfig: { title: 'Add Warehouse', isReplaceble: true },
        props: () => ({
            indexPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE,
            detailsPageRoute: DESCRIPTION_TEMPLATES_GRID_ROUTE, // there is none so we redirect to index page
        }),
        loader: () => import('@skunexus/description-templates-warehouses-form'),
    },
    warehouseConfigurations: {
        path: app.settingsWarehousesTabs.getItem('configuration').link,
        title: app.settingsWarehousesTabs.getItem('configuration').title,
        permission: app.settingsWarehousesTabs.getItem('configuration').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('configuration').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: WAREHOUSE_CONFIGURATION_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/warehouses-configuration'),
        render: withSettingsTabsWrapper(app),
    },
    warehouseConfigurationForm: {
        path: WAREHOUSE_CONFIGURATION_FORM_PATH,
        title: `${WAREHOUSE_CONFIGURATION_TITLE_PREFIX} Form`,
        breadcrumbConfig: { title: 'Add Warehouse Configuration', isReplaceble: true },
        props: () => ({
            indexPageRoute: WAREHOUSE_CONFIGURATION_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/warehouse-cf-form'),
    },
    carts: {
        path: app.settingsWarehousesTabs.getItem('carts').link,
        title: app.settingsWarehousesTabs.getItem('carts').title,
        permission: app.settingsWarehousesTabs.getItem('carts').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('carts').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: CARTS_FORM_ROUTE,
            totesGridPageRoute: CARTS_TOTES_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/carts-grid'),
        render: withSettingsTabsWrapper(app),
    },
    cartTotes: {
        path: CARTS_TOTES_GRID_PATH,
        title: `Cart Totes`,
        permission: 'totes.index',
        breadcrumbConfig: { title: `Cart Totes`, isReplaceble: true },
        loader: () => import('@skunexus/cart-totes-grid'),
    },
    cartsForm: {
        path: CARTS_FORM_PATH,
        breadcrumbConfig: { title: 'Add Cart', isReplaceble: true },
        props: () => ({
            indexPageRoute: CARTS_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/carts-form'),
    },
    totes: {
        path: app.settingsWarehousesTabs.getItem('totes').link,
        title: app.settingsWarehousesTabs.getItem('totes').title,
        permission: app.settingsWarehousesTabs.getItem('totes').permission,
        breadcrumbConfig: {
            title: `${app.settingsWarehousesTabs.getItem('totes').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: () => ({
            formPageRoute: TOTES_FORM_ROUTE,
        }),
        loader: () => import('@skunexus/totes-grid'),
        render: withSettingsTabsWrapper(app),
    },
    toteForm: {
        path: TOTES_FORM_PATH,
        breadcrumbConfig: { title: 'Add Tote', isReplaceble: true },
        props: () => ({
            indexPageRoute: TOTES_GRID_ROUTE,
        }),
        loader: () => import('@skunexus/tote-form'),
    },
});
