import get from 'lodash/get';
import { selectors } from '@skunexus/utils';

import UserModel from '../../../UserModel';
import withVendorTabsWrapper from './components/withVendorTabsWrapper';

import {
    VENDORS_TITLE_PREFIX,
    VENDOR_TITLE_PREFIX,
    VENDOR_INDEX_ROUTE,
    VENDOR_FORM_PATH,
    VENDOR_CREATE_FORM_ROUTE,
    VENDOR_EDIT_FORM_ROUTE,
    VENDOR_DETAILS_ROUTE,
    VENDOR_ADDRESSES_GRID_ROUTE,
    VENDOR_ADDRESSES_FORM_PATH,
    VENDOR_ADDRESSES_CREATE_FORM_ROUTE,
    VENDOR_ADDRESSES_EDIT_FORM_ROUTE,
    VENDOR_CONTACTS_GRID_ROUTE,
    VENDOR_CONTACTS_FORM_PATH,
    VENDOR_CONTACTS_CREATE_FORM_ROUTE,
    VENDOR_CONTACTS_EDIT_FORM_ROUTE,
    VENDOR_PRODUCTS_GRID_ROUTE,
    VENDOR_PRODUCTS_DETAILS_ROUTE,
    VENDOR_PRODUCTS_FORM_PATH,
    VENDOR_PRODUCTS_EDIT_FORM_ROUTE,
} from './consts';

import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { PO_INDEX_ROUTE, PO_DETAILS_PATH, PO_CREATE_FORM_ROUTE } from '../purchaseOrders/consts';
import { ORDERS_INDEX_ROUTE } from '../orders/consts';

export default (app) => ({
    vendors: {
        path: VENDOR_INDEX_ROUTE,
        title: VENDORS_TITLE_PREFIX,
        permission: ['vendors.index'],
        breadcrumbConfig: { title: `${VENDORS_TITLE_PREFIX}`, clearPath: true },
        props: () => ({
            detailsPageRoute: get(app.vendorTabs.getAll(), 'overview.link', ''),
            formPageRouteAdd: VENDOR_CREATE_FORM_ROUTE,
            formPageRouteEdit: `${VENDOR_EDIT_FORM_ROUTE}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-grid'),
    },
    vendorForm: {
        path: VENDOR_FORM_PATH,
        title: `${VENDOR_TITLE_PREFIX} Form`,
        permission: ['vendors.create', 'vendors.update'],
        breadcrumbConfig: { title: 'Add Vendor', isReplaceble: true },
        props: () => ({
            indexPageRoute: VENDOR_INDEX_ROUTE,
            detailsPageRoute: `${VENDOR_DETAILS_ROUTE}/:vendorId`,
            canEditCustomValues: UserModel.getCurrentUser().hasAllPermissions('vendors - custom attributes.value edit'),
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-form'),
    },
    vendorOverview: {
        path: app.vendorTabs.getItem('overview').link,
        title: app.vendorTabs.getItem('overview').title,
        permission: app.vendorTabs.getItem('overview').permission,
        breadcrumbConfig: {
            title: `${app.vendorTabs.getItem('overview').label}`,
            isReplaceble: true,
            isOverview: true,
        },
        props: () => {
            let visibleAttributes;
            if (UserModel.isVendor()) {
                visibleAttributes = app.general.getItem('modules.vendorOverview.vendorVisibleAttributes') || [];
            }
            return {
                gridPageRoute: VENDOR_INDEX_ROUTE,
                formPageRoute: `${VENDOR_EDIT_FORM_ROUTE}/:vendorId`,
                canEdit: UserModel.getCurrentUser().hasAllPermissions('vendors.update'),
                isVendorMode: UserModel.isVendor(),
                visibleAttributes,
            };
        },
        loader: () => import('@skunexus/vendor-overview'),
        render: withVendorTabsWrapper(app),
    },
    vendorAddresses: {
        path: app.vendorTabs.getItem('addresses').link,
        title: app.vendorTabs.getItem('addresses').title,
        permission: app.vendorTabs.getItem('addresses').permission,
        breadcrumbConfig: { title: `${app.vendorTabs.getItem('addresses').label}`, isReplaceble: true, isTab: true },
        props: (state) => ({
            formAddPageRoute: VENDOR_ADDRESSES_CREATE_FORM_ROUTE,
            formEditPageRoute: VENDOR_ADDRESSES_EDIT_FORM_ROUTE,
            idNameMap: selectors.getIDNameMap(state.app),
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-address-grid'),
        render: withVendorTabsWrapper(app),
    },
    vendorAddressesForm: {
        path: VENDOR_ADDRESSES_FORM_PATH,
        title: `${VENDOR_TITLE_PREFIX} Address Form`,
        permission: ['vendors - addresses.create', 'vendors - addresses.update'],
        breadcrumbConfig: { title: 'Add Address', isReplaceble: true },
        props: () => ({
            indexPageRoute: `${VENDOR_ADDRESSES_GRID_ROUTE}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-address-form'),
    },
    vendorContacts: {
        path: app.vendorTabs.getItem('contacts').link,
        title: app.vendorTabs.getItem('contacts').title,
        permission: app.vendorTabs.getItem('contacts').permission,
        breadcrumbConfig: { title: `${app.vendorTabs.getItem('contacts').label}`, isReplaceble: true, isTab: true },
        props: (state) => ({
            formAddPageRoute: VENDOR_CONTACTS_CREATE_FORM_ROUTE,
            formEditPageRoute: VENDOR_CONTACTS_EDIT_FORM_ROUTE,
            idNameMap: selectors.getIDNameMap(state.app),
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-contact-grid'),
        render: withVendorTabsWrapper(app),
    },
    vendorContactForm: {
        path: VENDOR_CONTACTS_FORM_PATH,
        title: `${VENDOR_TITLE_PREFIX} Contact Form`,
        permission: ['vendors - contacts.create', 'vendors - contacts.update'],
        breadcrumbConfig: { title: 'Add Contact', isReplaceble: true },
        props: () => ({
            indexPageRoute: `${VENDOR_CONTACTS_GRID_ROUTE}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-contact-form'),
    },
    vendorProducts: {
        path: app.vendorTabs.getItem('products').link,
        title: app.vendorTabs.getItem('products').title,
        permission: app.vendorTabs.getItem('products').permission,
        breadcrumbConfig: { title: `${app.vendorTabs.getItem('products').label}`, isReplaceble: true, isTab: true },
        props: (state) => ({
            productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
            updateFormPageRoute: VENDOR_PRODUCTS_EDIT_FORM_ROUTE,
            detailsPageRoute: VENDOR_PRODUCTS_DETAILS_ROUTE,
            permissions: {
                create: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.create'),
                deactive: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.update'),
                edit: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.update'),
                view: UserModel.getCurrentUser().hasAnyPermissions('vendors - products.show'),
                delete: UserModel.getCurrentUser().hasAnyPermissions([
                    'vendors - products.delete',
                    'vendors - products.delete multiple',
                ]),
            },
            canEditPrice: true,
            canEditSku: true,
            idNameMap: selectors.getIDNameMap(state.app),
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-product-grid'),
        render: withVendorTabsWrapper(app),
    },
    vendorProductsForm: {
        path: VENDOR_PRODUCTS_FORM_PATH,
        title: 'Vendor Products Form',
        permission: ['vendors - products.update'],
        breadcrumbConfig: { title: 'Add Product', isReplaceble: true },
        props: () => ({
            detailsPageRoute: `${VENDOR_PRODUCTS_GRID_ROUTE}/:vendorId`,
        }),
        loader: () => import('@skunexus/vendor-product-form'),
    },
    vendorProductsDetails: {
        path: VENDOR_PRODUCTS_DETAILS_ROUTE,
        title: 'Vendor Products Details',
        permission: ['vendors - products.show', 'vendors - products.update'],
        breadcrumbConfig: { title: 'Details', isReplaceble: true },
        props: () => ({
            gridPageRoute: VENDOR_PRODUCTS_GRID_ROUTE,
            updateFormPageRoute: VENDOR_PRODUCTS_EDIT_FORM_ROUTE,
            vendorOverviewPageRoute: VENDOR_DETAILS_ROUTE,
            productOverviewPageRoute: PRODUCTS_DETAILS_ROUTE,
            currentUserId: '/:vendorId',
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-product-details'),
    },
    vendorPurchaseOrders: {
        path: app.vendorTabs.getItem('purchaseOrders').link,
        title: app.vendorTabs.getItem('purchaseOrders').title,
        permission: app.vendorTabs.getItem('purchaseOrders').permission,
        breadcrumbConfig: {
            title: `${app.vendorTabs.getItem('purchaseOrders').label}`,
            isReplaceble: true,
            isTab: true,
        },
        props: (state) => ({
            formAddPageRoute: PO_CREATE_FORM_ROUTE,
            viewPageRoute: PO_DETAILS_PATH,
            purchaseOrderDetailsPageRoute: PO_INDEX_ROUTE,
            orderDetailsPageRoute: ORDERS_INDEX_ROUTE,
            permissions: {
                create: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
                orderOnlyAdmin: UserModel.getCurrentUser().hasAnyPermissions('purchase orders.create'),
            },
            idNameMap: selectors.getIDNameMap(state.app),
            isVendorMode: UserModel.isVendor(),
        }),
        loader: () => import('@skunexus/vendor-purchase-order-grid'),
        render: withVendorTabsWrapper(app),
    },
    // DISABLED FOR NOW AS WE DONT USE IT
    // vendorReports: {
    //     path: app.vendorTabs.getItem('reportsAndPayouts').link,
    //     title: app.vendorTabs.getItem('reportsAndPayouts').title,
    //     permission: app.vendorTabs.getItem('reportsAndPayouts').permission,
    //     breadcrumbConfig: {
    //         title: `${app.vendorTabs.getItem('reportsAndPayouts').label}`,
    //         isReplaceble: true,
    //         isTab: true,
    //     },
    //     props: (state) => ({
    //         idNameMap: selectors.getIDNameMap(state.app),
    //         isVendorMode: UserModel.isVendor(),
    //     }),
    //     loader: () => {
    //         return import('@skunexus/vendor-reports-payouts').then((ModuleConfig) => {
    //             return {
    //                 ...ModuleConfig,
    //                 Module: ModuleConfig.MerchantModule,
    //             };
    //         });
    //     },
    //     render: withVendorTabsWrapper(app),
    // },
});
