import GraetersShipmentDetails from './packages/ShippingPrintLabel/components/GraetersShipmentDetails';
import { customBatchingItemsTableConfig } from './helpers/batchingPrintCustom';

export default {
    name: 'shippingPrintLabel',
    before: [],
    after: [],
    setup: (app) => {
        const shipment = app.routes.getItem('shipment');
        if (shipment) {
            const shipmentProps = shipment.props;

            shipment.props = (state) => ({
                ...shipmentProps(state),
                printShippingDetailsComponent: GraetersShipmentDetails,
            });
            app.routes.changeItem('shipment', shipment);
        }

        const shipmentPrint = app.routes.getItem('shipmentPrint');
        if (shipmentPrint) {
            const shipmentPrintProps = shipmentPrint.props;

            shipmentPrint.props = (state) => ({
                ...shipmentPrintProps(state),
                printShippingDetailsComponent: GraetersShipmentDetails,
            });
            app.routes.changeItem('shipmentPrint', shipmentPrint);
        }

        const fulfillmentShipDetails = app.routes.getItem('fulfillmentShipDetails');
        if (fulfillmentShipDetails) {
            const fulfillmentShipDetailsProps = fulfillmentShipDetails.props;

            fulfillmentShipDetails.props = (state) => ({
                ...fulfillmentShipDetailsProps(state),
                printShippingDetailsComponent: GraetersShipmentDetails,
            });
            app.routes.changeItem('fulfillmentShipDetails', fulfillmentShipDetails);
        }

        const batchingPrint = app.routes.getItem('fulfillmentShipBatchingPrint');
        if (batchingPrint) {
            const batchingPrintProps = batchingPrint.props;

            batchingPrint.props = (state) => ({
                ...batchingPrintProps(state),
                shipmentDetailsComponent: GraetersShipmentDetails,
                customTableData: customBatchingItemsTableConfig,
                customBatchItemsFields: ['quantity_cases', 'quantity_individual'],
            });
            app.routes.changeItem('fulfillmentShipBatchingPrint', batchingPrint);
        }
    },
};
