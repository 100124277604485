export default {
    name: 'massPrintShipmentLabel',
    before: [],
    after: [],
    setup: (app) => {
        const fulfillmentShipPrintLogsRoute = app.routes.getItem('fulfillmentShipPrintLogs');
        if (fulfillmentShipPrintLogsRoute) {
            fulfillmentShipPrintLogsRoute.loader = () => import('./packages/FulfillmentPrintLogs/core');

            app.routes.changeItem('fulfillmentShipPrintLogs', fulfillmentShipPrintLogsRoute);
        }
    },
};
