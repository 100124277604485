import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

import { TITLE_PREFIX } from './consts';

export default (app) => {
    const routers = {
        orderDecisionSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionSettings').permission,
            breadcrumbConfig: {
                title: `${TITLE_PREFIX}s - ${app.settingsOrderTabs.getItem('orderDecisionSettings').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => {},
            loader: () => import('@skunexus/order-decision-settings'),
            render: withSettingsTabsWrapper(app),
        },
        orderDecisionRuleSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').permission,
            breadcrumbConfig: {
                title: `${app.settingsOrderTabs.getItem('orderDecisionRuleSettings').label}`,
                isTab: true,
            },
            props: () => {},
            loader: () => import('@skunexus/order-decision-rules-settings'),
            render: withSettingsTabsWrapper(app),
        },
    };

    return routers;
};
