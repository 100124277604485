import preferredWarehouses from './preferredWarehouses';
import customOrderDetails from './customOrderDetails';
import customProductStockStatuses from './customProductStockStatuses';
import customFulfillments from './customFulfillments';
import exportProductStock from './exportProductStock';
import massPrintShipmentLabel from './massPrintShipmentLabel';
import shippingPrintLabel from './shippingPrintLabel';
import reports from './reports';

export default {
    preferredWarehouses,
    customOrderDetails,
    customProductStockStatuses,
    customFulfillments,
    exportProductStock,
    massPrintShipmentLabel,
    shippingPrintLabel,
    reports,
};
