import { connect } from 'react-redux';

import { actions as notification } from '@skunexus/notification';
import { actions as orderDetailsActions } from '@skunexus/order-details';
import Component from './OnHoldButton';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    errorNotification: notification.error,
    successNotification: notification.success,
    fetchDetails: orderDetailsActions.details.detailsReceive.TRIGGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
