import React from 'react';
import get from 'lodash/get';

export const customBatchingItemsTableConfig = (data) => {
    data.columns.cases = {
        label: 'Cases',
        field: 'quantity_cases',
        defaultValue: 0,
        order: 50,
    };

    data.columns.individual = {
        label: 'Individual',
        field: 'quantity_individual',
        defaultValue: 0,
        order: 60,
    };

    data.total = (items) => (
        <tr>
            <td colSpan="3" style={{ textAlign: 'right' }}>
                <strong>Totals:</strong>
            </td>

            <td>
                <strong>{items.reduce((result, item) => result + item.quantity, 0)}</strong>
            </td>

            <td>
                <strong>{items.reduce((result, item) => result + get(item, 'quantity_cases', 0), 0)}</strong>
            </td>

            <td>
                <strong>{items.reduce((result, item) => result + get(item, 'quantity_individual', 0), 0)}</strong>
            </td>
        </tr>
    );

    return data;
};
