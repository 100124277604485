import find from 'lodash/find';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

export const getCustomFieldValue = (data, code, defaultReturn = null) => {
    if (isArray(get(data, 'customValues'))) {
        const field = find(get(data, 'customValues', []), { custom_field_id: code });
        if (field) return field.value;
    }

    if (isObject(get(data, 'customValues'))) {
        return get(get(data, 'customValues', {}), code, defaultReturn);
    }

    return defaultReturn;
};
