import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@skunexus/component-catalog';
import { ajaxExceptionMessage } from '@skunexus/utils';

import * as API from './api';

function OnHoldButton({ order, errorNotification, successNotification, fetchDetails }) {
    const [isLoading, setLoading] = useState(false);

    const isOrderOnHold = useCallback(() => order.state === 'On Hold', [order.state]);

    const handleClick = async () => {
        setLoading(true);
        try {
            const endpoint = isOrderOnHold() ? API.unholdOrder : API.holdOrder;
            
            await endpoint({ orderId: order.id });
            successNotification(`Order set on ${isOrderOnHold() ? 'unhold' : 'hold'}`);
            fetchDetails({ id: order.id });
            setLoading(false);
        } catch (e) {
            errorNotification(ajaxExceptionMessage(e));
            setLoading(false);
        }
    };

    return (
        <Button color="secondary" onClick={handleClick} aria-label="On hold" disabled={isLoading}>
            {isOrderOnHold() ? 'Unhold' : 'Hold'} Order
        </Button>
    );
}

OnHoldButton.propTypes = {
    order: PropTypes.object.isRequired,
    errorNotification: PropTypes.func.isRequired,
    successNotification: PropTypes.func.isRequired,
    fetchDetails: PropTypes.func.isRequired,
};

OnHoldButton.defaultProps = {};

export default OnHoldButton;
