import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';

import { getCustomFieldValue } from '../../utils';

import styles from '../../styles.module.css';

function ShippingAddress({
    data: { street1 = '', street2 = '', city = '', postcode = '', region = '', country = '' } = {},
}) {
    return (
        <div>
            <div>
                {street1} {street2} {city},
            </div>
            <div>
                {region} {postcode} {country}
            </div>
        </div>
    );
}

ShippingAddress.propTypes = {
    data: PropTypes.object,
};

ShippingAddress.defaultProps = {
    data: {},
};

function Details({ details }) {
    const estimateDeliveryDate = get(details, 'relatedShipment.estimated_delivery', '');

    return (
        <div className={styles.detailsContent}>
            <div className={styles.detailsSection} />
            <div className={styles.detailsSection}>
                <div className={styles.detailsItem}>
                    <strong>Sold To: </strong>
                    <span>
                        {`${getCustomFieldValue(details.relatedOrder, 'customer_first_name', '')} ${getCustomFieldValue(
                            details.relatedOrder,
                            'customer_last_name',
                            '',
                        )}`}
                    </span>
                </div>
                <div className={styles.detailsItem}>
                    <strong>Ship To:</strong>
                    <ShippingAddress data={get(details, 'relatedShipment.shippingAddress', {})} />
                </div>
            </div>
            <div className={styles.detailsSection}>
                <div className={styles.detailsItem}>
                    <strong>Order #:</strong>
                    <span>{get(details, 'relatedOrder.label', '')}</span>
                </div>
                <div className={styles.detailsItem}>
                    <strong>Sold To Email:</strong>
                    <span>{getCustomFieldValue(details.relatedOrder, 'customer_email', '')}</span>
                </div>

                <div className={styles.detailsItem}>
                    <strong>Ship To Phone:</strong>
                    <span>{getCustomFieldValue(details.relatedOrder, 'customer_phone', '')}</span>
                </div>

                <div className={styles.detailsItem}>
                    <strong>Ship Date:</strong>
                    <span>{estimateDeliveryDate ? moment(estimateDeliveryDate).format('MM/DD/YYYY') : ''}</span>
                </div>
            </div>
        </div>
    );
}

Details.propTypes = {
    details: PropTypes.object,
};

Details.defaultProps = {
    details: {},
};

export default Details;
